import React, { useCallback } from 'react'
import { Button, Card, Col, Container, Form, InputGroup, Modal, Navbar, NavbarBrand, Row, Spinner } from "react-bootstrap";
import Empty from '../../components/Empty';
import { ListUl, PeopleFill, PlusLg } from "react-bootstrap-icons";
import Member from '../../components/Member';

import AdminPdgComponent from '../../components/admin/AdminPdgComponent';


const AdminPdg = () => {

    const [year, setYear] = React.useState('');
    const [data, setLists] = React.useState([]);
    const [searchTerm, setSearchTerm] = React.useState("");
    const [currentUid, setCurrentUid] = React.useState(0)
    const [show, setShow] = React.useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [progress, setProgress] = React.useState(false)
    const [searched, setSearched] = React.useState([])


    const getData = useCallback(async () => {
        const response = await fetch(`/v1/pdg`, { method: "GET", headers: { Authorization: localStorage.getItem('_adminAuth') } })
        const data = await response.json()
        setLists(data)
    }, [])


    React.useEffect(() => {
        getData();
    }, [getData])



    const handleSearch = async () => {
        console.log("Searching for:", searchTerm);
        setProgress(true)
        if (searchTerm.length === 0) {
            return
        }
        const response = await fetch(`/v1/member?q=${searchTerm}`, { method: 'GET', headers: { 'Authorization': localStorage.getItem("_adminAuth") } })

        if (response.ok) {
            const data = await response.json()
            setProgress(false)
            setSearched(data)
        } else {
            setProgress(false)
        }
    };


    const clickHandler = (user) => {
        setCurrentUid(user.uid)
        handleClose()
    }


    const addList = async () => {
        const d = await fetch(`/v1/pdg`,
            {
                method: 'POST',
                headers: { 'Authorization': localStorage.getItem("_adminAuth"), 'Accept': 'application/json', 'Content-Type': 'application/json' },
                body: JSON.stringify({ id: currentUid, year: year }),
            }
        )
        if (d.ok) {
            await getData()
        }
    }

    const deleteData = async (id) => {
        let filter = data.filter(item => item.pdg_id !== id)
        setLists(filter)
        await fetch('/v1/pdg/' + id, { method: "DELETE", headers: { Authorization: localStorage.getItem("_adminAuth") } })
    }

    return (
        <>
            <Navbar expand="lg" bg="dark" className='border-bottom sticky-top' data-bs-theme="dark">
                <Container fluid>
                    <NavbarBrand>
                        PDG
                    </NavbarBrand>
                </Container>
            </Navbar>

            <Container fluid={true}>
                <Row>
                    <Col lg={8}>
                        {
                            data.length === 0 ? <Empty /> :
                                <Row>
                                    {
                                        data.map((item) => (
                                            <Col lg={4} key={item.id}>
                                                <AdminPdgComponent data={item} deleteHandler={deleteData} />
                                            </Col>
                                        ))
                                    }
                                </Row>
                        }
                    </Col>
                    <Col lg={4}>
                        <Card className={'mt-2'}>
                            <Card.Header>
                                <Card.Title>
                                    <ListUl /> PDG
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Form>
                                    <Form.Group className="mb-1">
                                        <Form.Label>Member ID</Form.Label>
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <Form.Control type="text" disabled value={currentUid} placeholder="Member ID" className='rounded-0' />
                                            <Button variant='primary' onClick={handleShow} className='rounded-0'><PeopleFill /></Button>
                                        </div>
                                    </Form.Group>
                                    <InputGroup className="mb-3 mt-3">
                                        <InputGroup.Text id="basic-addon3">
                                            Year
                                        </InputGroup.Text>
                                        <Form.Control name='sequence' onChange={(e) => setYear(e.target.value)} value={year} aria-describedby="basic-addon3" />
                                    </InputGroup>
                                </Form>
                            </Card.Body>
                            <Card.Footer>
                                <Button variant={'primary'} onClick={addList}><PlusLg /> Add</Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </Container>


            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>SEARCH MEMBER</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="d-flex">
                        <Form.Control type="text" value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)} placeholder="Search by name or Phone or ID" />
                        <Button className="ms-2" onClick={handleSearch}>Search</Button>
                    </Form>
                    {
                        searched.map((user, index) => (
                            <Member key={index} data={user} memberClick={clickHandler} />
                        ))
                    }
                </Modal.Body>
                <Modal.Footer>
                    {
                        progress ? <div className='d-flex justify-content-center align-items-center'>
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div> : "Use name, member ID or phone number"
                    }
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AdminPdg