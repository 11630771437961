import React from 'react'
import { Spinner } from 'react-bootstrap'
import Empty from "../../components/Empty";
import PublicPdgComponent from '../../components/user/PublicPdgComponent';


const PublicPdg = () => {

    const [lists, setList] = React.useState([])

    const [loading, setLoading] = React.useState(true)

    React.useEffect(() => {
        ; (async () => {
            const response = await fetch(`/v1/pdg`, { method: "GET", headers: { 'authorization': localStorage.getItem("_memberAuth") } })
            setLoading(false)
            if (response.ok) {
                const json = await response.json()
                setList(json)
            } else {

            }
        })()
    }, [])


    return (
        <>
            {
                loading ?
                    <div className={'d-flex justify-content-center align-items-center'}>
                        <Spinner role={"alert"} className={'m-2'}></Spinner>
                    </div>
                    : <></>
            }
            {
                lists.length === 0 ? <Empty /> : <></>
            }
            {
                lists.map((list, index) => (
                    <PublicPdgComponent key={index} data={list} className='m-2' />
                ))
            }
        </>
    )
}

export default PublicPdg