import {
    Buildings, BuildingsFill,
    ChatSquareQuoteFill,
    CloudFill,
    ImageFill, People,
    PeopleFill,
    PersonRolodex,
    Speedometer2
} from 'react-bootstrap-icons'
import { Link } from 'react-router-dom'

const AdminSidePanel = () => {
    return (
        <div>
            <ul>
                <li>
                    <Link className='side-item' to='dashboard'>
                        <Speedometer2 />
                        <label>Dashboard</label>
                    </Link>
                </li>
                <li>
                    <Link className='side-item' to='banners'>
                        <ImageFill />
                        <label>Banners</label>
                    </Link>
                </li>
                <li>
                    <Link className='side-item' to='messages'>
                        <ChatSquareQuoteFill />
                        <label>Oath</label>
                    </Link>
                </li>
                <li>
                    <Link className='side-item' to='list/pips'>
                        <People />
                        <label>PIPS</label>
                    </Link>
                </li>
                <li>
                    <Link className='side-item' to='list/pid'>
                        <People />
                        <label>PID</label>
                    </Link>
                </li>
                <li>
                    <Link className='side-item' to='pdg'>
                        <People />
                        <label>PDG</label>
                    </Link>
                </li>
                <li>
                    <Link className='side-item' to='list/office'>
                        <Buildings />
                        <label>Office</label>
                    </Link>
                </li>
                <li>
                    <Link className='side-item' to='members'>
                        <PeopleFill />
                        <label>Members</label>
                    </Link>
                </li>
                <li>
                    <Link className='side-item' to='clubs'>
                        <BuildingsFill />
                        <label>Clubs</label>
                    </Link>
                </li>
                <li>
                    <Link className='side-item' to='cabinet'>
                        <PersonRolodex />
                        <label>Cabinets</label>
                    </Link>
                </li>
                <li>
                    <Link className='side-item' to='regions'>
                        <CloudFill />
                        <label>Regions</label>
                    </Link>
                </li>
            </ul>
        </div>
    )
}

export default AdminSidePanel