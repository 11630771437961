import React from 'react'
import { useNavigate } from 'react-router-dom';


const PublicPdgComponent = ({ data }) => {

    const navigator = useNavigate()
    const viewUser = () => {
        navigator('/user/' + data.uid)
    }

    return (
        <div role="button" onClick={viewUser} className="mb-2 mt-2 rounded border d-flex align-items-center" style={{ height: "80px" }}>
            <img className="rounded ms-2 me-2" width={64} height={64} src={(data.picture === null) ? "/lion.png" : data.picture} alt={data.name} />
            <div>
                <h5>{data.name}</h5>
                <p>{data.year}</p>
            </div>
        </div>
    )
}

export default PublicPdgComponent