import React from 'react';
import { Button, Card } from "react-bootstrap";
import { BuildingFill, Calendar2HeartFill, Trash2Fill } from "react-bootstrap-icons";

const AdminPdgComponent = ({ data, deleteHandler }) => {
    return (
        <Card className='mt-2 mb-2'>
            <Card.Body>
                <div className='d-flex align-items-center'>
                    <img width={64} height={64} className='rounded-2 me-2' src={(data.picture === null) ? "/lion.png" : data.picture} alt={data.name} />
                    <div>
                        <Card.Title>{data.name}</Card.Title>
                        <Card.Text><BuildingFill /> {data.club_name}</Card.Text>
                    </div>
                </div>
            </Card.Body>
            <Card.Footer className="d-flex justify-content-between">
                <div>
                    <Card.Text><Calendar2HeartFill /> {data.year}</Card.Text>
                </div>
                <Button variant="outline-danger" onClick={() => deleteHandler(data.pdg_id)}><Trash2Fill /></Button>
            </Card.Footer>
        </Card>
    )
}

export default AdminPdgComponent