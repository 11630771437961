import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Navbar, NavbarBrand, Button, Modal, Form, InputGroup } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import Clubs from '../../components/Clubs'
import { BuildingAdd } from 'react-bootstrap-icons'
import AddZone from '../../components/AddZone'
import AdminSearchClub from '../../components/admin/AdminSearchClub'
import AdminZone from "../../components/admin/AdminZone";

const AdminZoneRoute = () => {

    const { id } = useParams()
    const [currentZid, setZid] = useState(0)
    const [zones, setZones] = useState([])
    const [clubs, setClubs] = useState([])
    const [allClubs, setAllClubs] = useState([])
    const [html, setHtml] = useState('')
    const [inputValue, setInputValue] = useState('')
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [searched, setSearched] = useState([])

    const [changeModal, setChangeModal] = useState(false)
    const [activeZC, setActiveZC] = useState({ zid: '', uid: '' })

    useEffect(() => {
        ; (async () => {
            const response = await fetch(`/v1/clubs`, { method: "GET", headers: { Authorization: localStorage.getItem('_adminAuth') } })
            const data = await response.json()
            setAllClubs(data);
        })()
    }, [])

    useEffect(() => {
        setHtml(
            clubs.map((club) => (
                <Clubs data={club} navigateUrl="/admin/club/" />
            ))
        )
    }, [clubs])

    useEffect(() => {
        ; (async () => {
            await fetchRegion(id)
        })()
    }, [id])

    useEffect(() => {
        const filteredClubs = allClubs.filter((club) =>
            club.club_name.toLowerCase().includes(inputValue.toLowerCase())
        );
        setSearched(filteredClubs);
    }, [inputValue, allClubs])



    const fetchRegion = async (id) => {
        const response = await fetch("/v1/region/" + id, { method: "GET", headers: { "Content-Type": "application/json", Authorization: `${localStorage.getItem('_adminAuth')}` } })
        const data = await response.json()
        setZones(data)
    }

    const viewClubs = async (zid) => {
        const response = await fetch("/v1/zone/" + zid, { method: "GET", headers: { "Content-Type": "application/json", Authorization: `${localStorage.getItem('_adminAuth')}` } })
        const data = await response.json()
        setClubs(data)
    }

    const addZone = async (label, uid) => {
        const response = await fetch("/v1/zone/", { method: "POST", headers: { 'Content-Type': 'application/json', Authorization: localStorage.getItem('_adminAuth') }, body: JSON.stringify({ region: id, label: label, member: uid }) })
        if (response.ok) {
            alert("Zone added...")
            await fetchRegion(id)
        } else {
            alert("Failed to add")
        }
    }

    const addClubDialog = (zid) => {
        setZid(zid)
        handleShow()
    }
    const changeHandler = (data) => {
        setChangeModal(true)
        setActiveZC({ ...activeZC, zid: data.zid, uid: data.uid })
    }
    const changeZC = async () => {
        const response = await fetch("/v1/zone", { method: "PUT", headers: { 'authorization': localStorage.getItem("_adminAuth"), 'Content-Type': 'application/json' }, body: JSON.stringify(activeZC) })
        if (response.ok) {
            alert("Updated. Please refresh this page")
        }
    }

    const deleteHandler = async (data) => {
        await fetch(`/v1/zone/${data.zid}`, { method: "DELETE", headers: { 'authorization': localStorage.getItem("_adminAuth"), 'Content-Type': 'application/json' } })
        const newData = zones.filter(banner => banner.zid !== data.zid);
        setZones(newData);
    }

    return (
        <>
            <Navbar expand="lg" bg="dark" className='border-bottom sticky-top' data-bs-theme="dark">
                <Container fluid>
                    <NavbarBrand>Zones</NavbarBrand>
                    <Button onClick={() => setHtml(<AddZone submit={addZone} />)}> <BuildingAdd /> Add a Zone</Button>
                </Container>
            </Navbar>

            <Container fluid>
                <Row>
                    <Col lg={6}>
                        <Row>
                            {zones.map((zone) => (
                                <Col lg={6} key={zone.zid}>
                                    <AdminZone data={zone} viewHandler={viewClubs} addHandler={addClubDialog} changeHandler={changeHandler} deleteHandler={deleteHandler} />
                                </Col>
                            ))}
                        </Row>
                    </Col>

                    <Col lg={6}>
                        {html}
                    </Col>
                </Row>
            </Container>

            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>SEARCH CLUB</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="d-flex">
                        <Form.Control type="text" value={inputValue} onChange={(e) => setInputValue(e.target.value)} placeholder="Search Club name" />
                    </Form>

                    {
                        searched.map((club) => (
                            <AdminSearchClub data={club} zone={currentZid} />
                        ))
                    }
                </Modal.Body>
            </Modal>

            <Modal show={changeModal} onHide={() => setChangeModal(false)} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Zone management</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputGroup className="mb-3">
                        <InputGroup.Text>Member ID</InputGroup.Text>
                        <Form.Control
                            name='id'
                            value={activeZC.uid}
                            onChange={e => setActiveZC({ ...activeZC, uid: e.currentTarget.value })}
                            placeholder="Member ID"
                            aria-label="PublicCabinetRoute role"
                        />
                    </InputGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={changeZC}>Change ZC</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AdminZoneRoute